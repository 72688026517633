(function($){
    $(document).ready(function() {
        $('.Header-Menu-Left .menu .menu-item a').on('click', function(){
            $('.Header-Menu-Left .menu .menu-item').each(function(e){
                console.log(this);
                $(this).removeClass('fl-active');
                // $(this).find('.sub-menu').css({
                //     display: 'none'
                // });
            })
        })

    });
})(jQuery);